import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Form, Row, Col, Button, Alert, Table as BTable, Badge, Tabs, Tab } from 'react-bootstrap'
import { CloudUpload, ExclamationTriangle, PencilSquare } from 'react-bootstrap-icons'

import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import _ from 'lodash'
import domtoimage from 'dom-to-image'

import Papa from "papaparse";
import { ContentWrapper, Table, Modal, LoadingModal } from '../../components'

import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'

import './EditorialPage.css'
import { async } from 'regenerator-runtime'

import { exportData } from '../../helpers'
import CSVReader from 'react-csv-reader'
import Pagination from  '../../components/Pagination/Pagination';


const FareForm = ({ stops, edit, onClickCancel, onSubmitForm, defaultValues = { originId: '', destinationId: '0.00', amount: '', childAmount: '', seniorAmount: '', okuAmount: '', foreignAdultAmount: '', foreignChildAmount: '', currencyType: "MYR", destinationData: { name: 'null' }, originData: { name: 'null' } } }) => {
    const [originId, setOriginId] = useState(null)
    const [destinationId, setDestinationId] = useState(null)
    const [amount, setAmount] = useState(null)
    const [childAmount, setChildAmount] = useState(null)
    const [seniorAmount, setSeniorAmount] = useState(null)
    const [okuAmount, setOkuAmount] = useState(null)
    const [foreignAdultAmount, setForeignAdultAmount] = useState(null)
    const [foreignChildAmount, setForeignChildAmount] = useState(null)
    const [isSameAmountToAll, setIsSameAmountToAll] = useState(false)

    


    const handleBeforeSubmit = (e) => {
        e.preventDefault()
        const data = {}
        let appendStopIds = false

        if (originId !== null) {
            data.originId = originId
        }
        if (destinationId !== null) {
            data.destinationId = destinationId
        }
        if (isSameAmountToAll && amount !== null) {
            appendStopIds = true
            data.amount = amount
            data.childAmount = amount
            data.seniorAmount = amount
            data.okuAmount = amount
            data.foreignAdultAmount = amount
            data.foreignChildAmount = amount
        } else {
            if (amount !== null) {
                appendStopIds = true
                data.amount = amount
            }
            if (childAmount !== null) {
                appendStopIds = true
                data.childAmount = childAmount
            }
            if (seniorAmount !== null) {
                appendStopIds = true
                data.seniorAmount = seniorAmount
            }
            if (okuAmount !== null) {
                appendStopIds = true
                data.okuAmount = okuAmount
            }
            if (foreignAdultAmount !== null) {
                appendStopIds = true
                data.foreignAdultAmount = foreignAdultAmount
            }
            if (foreignChildAmount !== null) {
                appendStopIds = true
                data.foreignChildAmount = foreignChildAmount
            }
        }

        // --- fare table update integration ---
        if (stops) {
            data.id = defaultValues.id
        }

        if (!stops && appendStopIds) {
            data.originId = defaultValues.originId
            data.destinationId = defaultValues.destinationId
        }
        // --- fare table update integration ---

        onSubmitForm(data)

        if (onClickCancel) {
            onClickCancel()
        } else {
            setOriginId(null)
            setDestinationId(null)
            setAmount(null)
        }
    }

    const destinationStops = useMemo(() => {
        //just filter out stop from first selection
        if (originId === destinationId) {
            setDestinationId(null)
        }
        if (!stops || !originId) return []
        return stops.filter(({ id }) => id !== +originId)
    }, [originId])

    return (
        <Row>
            <Col className={` ${!edit ? 'pt-3' : ''} `} lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Create New Fare</Card.Title>
                            }
                            <Row>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Origin Stop</Form.Label>
                                        {
                                            stops ?
                                                <Form.Control value={originId ? originId : defaultValues.originId} onChange={(e) => setOriginId(e.currentTarget.value)} required={!edit ? true : false} custom as='select' >
                                                    <option value='' >NONE</option>
                                                    {
                                                        stops && stops.length > 0 &&
                                                        stops.map(({ name, id }, key) => (
                                                            <option value={id} key={key} >{name}</option>
                                                        ))
                                                    }
                                                </Form.Control>
                                                :
                                                <Form.Control readOnly value={defaultValues.originData.name} />
                                        }
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Destination Stop</Form.Label>
                                        {
                                            stops ?
                                                <Form.Control value={destinationId ? destinationId : defaultValues.destinationId} onChange={(e) => setDestinationId(e.currentTarget.value)} required={!edit ? true : false} custom as='select' >
                                                    <option value='' >NONE</option>
                                                    {
                                                        destinationStops && destinationStops.length > 0 &&
                                                        destinationStops.map(({ name, id }, key) => (
                                                            <option value={id} key={key} >{name}</option>
                                                        ))
                                                    }
                                                    {
                                                        edit && stops && stops.length > 0 &&
                                                        stops.map(({ name, id }, key) => (
                                                            <option value={id} key={key} >{name}</option>
                                                        ))
                                                    }
                                                </Form.Control>
                                                :
                                                <Form.Control readOnly value={defaultValues.destinationData.name} />
                                        }

                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Currency</Form.Label>
                                        <Form.Control defaultValue={defaultValues.currencyType} custom as='select' >
                                            <option value='MYR' >MYR</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={isSameAmountToAll ? 12 : 2} md={isSameAmountToAll ? 12 : 4} >
                                    <Form.Group>
                                        <Form.Label>{isSameAmountToAll ? 'Amount' : 'Adult'}</Form.Label>
                                        <Form.Control value={amount ? amount : +defaultValues.amount} onChange={(e) => setAmount(e.currentTarget.value)} required={!edit ? true : false} type='number' step='0.01' min='0.00' />
                                    </Form.Group>
                                </Col>
                                {
                                    !isSameAmountToAll &&
                                    <>
                                        <Col lg={2} md={4} >
                                            <Form.Group>
                                                <Form.Label>Child</Form.Label>
                                                <Form.Control disabled value={childAmount ? childAmount : +defaultValues.childAmount} onChange={(e) => setChildAmount(e.currentTarget.value)} type='number' step='0.01' min='0.00' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2} md={4} >
                                            <Form.Group>
                                                <Form.Label>Senior</Form.Label>
                                                <Form.Control disabled value={seniorAmount ? seniorAmount : +defaultValues.seniorAmount} onChange={(e) => setSeniorAmount(e.currentTarget.value)} type='number' step='0.01' min='0.00' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2} md={4} >
                                            <Form.Group>
                                                <Form.Label>OKU</Form.Label>
                                                <Form.Control disabled value={okuAmount ? okuAmount : +defaultValues.okuAmount} onChange={(e) => setOkuAmount(e.currentTarget.value)} type='number' step='0.01' min='0.00' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2} md={4} >
                                            <Form.Group>
                                                <Form.Label>Foreign Adult</Form.Label>
                                                <Form.Control disabled value={foreignAdultAmount ? foreignAdultAmount : +defaultValues.foreignAdultAmount} onChange={(e) => setForeignAdultAmount(e.currentTarget.value)} type='number' step='0.01' min='0.00' />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2} md={4} >
                                            <Form.Group>
                                                <Form.Label>Foreign Child</Form.Label>
                                                <Form.Control disabled value={foreignChildAmount ? foreignChildAmount : +defaultValues.foreignChildAmount} onChange={(e) => setForeignChildAmount(e.currentTarget.value)} type='number' step='0.01' min='0.00' />
                                            </Form.Group>
                                        </Col>
                                    </>
                                }
                            </Row>
                            {/* <Form.Group>
                                <Form.Label >Apply same amount in all category</Form.Label>
                                <Form.Check type='switch' onChange={(e) => setIsSameAmountToAll(e.target.checked)} defaultChecked={isSameAmountToAll} id='edit-changes-isSameAmountToAll' />
                            </Form.Group> */}
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            {
                                edit &&
                                <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            }
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Create' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row >
    )
}


const EditorialFare = () => {
    const [route, setRoute] = useState(null)
    const [refresh, setRefresh] = useState(null)
    // const [showEditModal, setShowEditModal] = useState(false)
    // const [selectedFareIndex, setSelectedFareIndex] = useState(null)
    const [showEditFareTableModal, setShowEditFareTableModal] = useState(false)
    const [selectedFareTable, setSelectedFareTable] = useState(null)
    const [childDiscount, setChildDiscount] = useState(0);
    const [foreignChildDiscount, setForeignChildDiscount] = useState(0);
    const [foreignAdultDiscount, setForeignAdultDiscount] = useState(0);
    const [seniorDiscount, setSeniorDiscount] = useState(0);
    const [okuDiscount, setOkuDiscount] = useState(0);
    const { routeId } = useParams()
    const [outboundStops, setOutboundStops] = useState(null)
    const [inboundStops, setInboundStops] = useState(null)
    const [routeType, setRouteType] = useState()
    const [key, setKey] = useState('outbound-fares');
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);


    let PageSize = 10;


    const handleGetRoute = () => {
        setLoading(true)

        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET}/${routeId}`
        }).then((data) => {
            // console.log("data", data);
            setRoute(data)
            setChildDiscount(data.childDiscount)
            setSeniorDiscount(data.seniorDiscount)
            setOkuDiscount(data.okuDiscount)
            setSeniorDiscount(data.seniorDiscount)
            setForeignChildDiscount(data.foreignChildDiscount)
            setForeignAdultDiscount(data.foreignAdultDiscount)

            if (data.stops[0].directionId == 0) {
                setRouteType("Loop")
                // setCurrentStopsOptions((prevState) => [...prevState, "Loop"])
            } else {
                setRouteType("Twoway")
                const inboundStops = data.stops.filter(({ directionId }) =>
                    directionId === 2
                )
                setInboundStops(inboundStops)
                const outboundStops = data.stops.filter(({ directionId }) =>
                    directionId === 1
                )
                setOutboundStops(outboundStops)

                // setCurrentStopsOptions((prevState) => [...prevState, "Inbound", "Outbound"])
            }

        }).catch(e => { }).finally(() => {
            setLoading(false)

        })

    }

    // const handleFareUpdate = (data) => {
    //     ApiRequest.fetch({
    //         method: 'put',
    //         url: `${API_END_POINTS.FARE_UPDATE}/${routeId}`,
    //         data
    //     }).then(() => {
    //         toast.success('Fare Updated!')
    //         setRefresh(!refresh)
    //     }).catch(e => { })
    // }

    const handleFareCreate = (data) => {
        if (!data.amount) {
            data.amount = '0.00'
        }
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.FARE_CREATE}/${routeId}`,
            data
        }).then(() => {
            toast.success(`New Fare Created!`)
            setRefresh(!refresh)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetRoute()
    }, [refresh])

    // const handleEdit = (index) => {
    //     setSelectedFareIndex(index)
    //     setShowEditModal(true)
    // }

    // const fareTableHeader = useMemo(() => [
    //     {
    //         Header: 'Origin Name',
    //         accessor: 'originName',
    //         // disableFilters: true
    //     },
    //     {
    //         Header: 'Destination Name',
    //         accessor: 'DesName',
    //         // disableFilters: true
    //     },
    //     {
    //         Header: 'Currency',
    //         accessor: 'currencyType',
    //         // disableFilters: true
    //     },
    //     {
    //         Header: 'Amount',
    //         accessor: 'amount',
    //         // disableFilters: true
    //     },
    //     // {
    //     //     Header: 'Edit',
    //     //     accessor: 'onEdit',
    //     //     disableFilters: true
    //     // }
    // ], [])

    // const appendAction = (data) => {
    //     data.map((row, i) => {
    //         row['originName'] = route.stops.find(({ id }) => id === row.originId).name
    //         row['DesName'] = route.stops.find(({ id }) => id === row.destinationId).name
    //         // row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
    //     })
    //     return data
    // }

    const handleClickCancel = () => {
        // setShowEditModal(false)
        // setSelectedFareIndex(null)
        setShowEditFareTableModal(false)
        setSelectedFareTable(null)
    }

    const fareHead = useMemo(() => {
        setLoading(true)

        if (!route || !routeType) return []
        let head
        if (routeType == 'Loop') {

            head = _.uniqBy([...route.stops], 'id')
        } else if (routeType == 'Twoway') {

            if (key == 'inbound-fares') {
                head = _.uniqBy([...route.stops.filter(({ directionId }) =>
                    directionId === 1
                )], 'id')

            } else if (key == 'outbound-fares') {
                head = _.uniqBy([...route.stops.filter(({ directionId }) =>
                    directionId === 2
                )], 'id')
            } 

        }
        // const head = [...route.stops]
        head.splice(0, 0, { name: 'From \\ To' })

        // console.log("fareHead", head);


        return head
    }, [route, routeType, key, refresh])

    const fareVals = useMemo(() => {
        setLoading(true)

        if (!route || !routeType) return []
        const groupByOriginId = _.groupBy(route.fares, 'originId')
        let head
        if (routeType == 'Loop') {

            head = _.uniqBy([...route.stops], 'id')
        } else if (routeType == 'Twoway') {

            if (key == 'inbound-fares') {
                head = _.uniqBy([...route.stops.filter(({ directionId }) =>
                    directionId === 1
                )], 'id')

            } else if (key == 'outbound-fares') {
                head = _.uniqBy([...route.stops.filter(({ directionId }) =>
                    directionId === 2
                )], 'id')
            }

        }
        const vals = []
        head.forEach(({ name, id }) => {
            const colVal = _.fill(Array(head.length), 'N/A')
            const sameStopIdx = head.findIndex(({ id: _id }) => _id === id)
            colVal[sameStopIdx] = 'x'
            const dataArr = groupByOriginId[id] || []
            dataArr.forEach(({ destinationId, amount }) => {
                const toIdx = head.findIndex(({ id: _id }) => _id === destinationId)
                colVal[toIdx] = amount
            })
            colVal.splice(0, 0, name)
            vals.push(colVal)
        })

        // console.log("farevalues", vals);
        setLoading(false)


        return vals
    }, [route, routeType, key, refresh])

    const goTo = useHistory()

    const handleFareTableEdit = useCallback((fromStop, toStop, amount) => {
        let sel = {
            originData: fromStop,
            destinationData: toStop,
            originId: fromStop.id,
            destinationId: toStop.id,
            amount: amount === "N/A" ? 0 : amount
        }
        const a = route?.fares?.find(({ originId, destinationId }) => originId === fromStop?.id && destinationId === toStop?.id)
        if (a) {
            const { childAmount, seniorAmount, okuAmount, foreignAdultAmount, foreignChildAmount } = a
            sel = {
                ...sel,
                childAmount: childAmount === "N/A" ? 0 : childAmount,
                seniorAmount: seniorAmount === "N/A" ? 0 : seniorAmount,
                okuAmount: okuAmount === "N/A" ? 0 : okuAmount,
                foreignAdultAmount: foreignAdultAmount === "N/A" ? 0 : foreignAdultAmount,
                foreignChildAmount: foreignChildAmount === "N/A" ? 0 : foreignChildAmount,
            }
        } else {
            sel = {
                ...sel,
                childAmount: 0,
                seniorAmount: 0,
                okuAmount: 0,
                foreignAdultAmount: 0,
                foreignChildAmount: 0,
            }
        }

        setShowEditFareTableModal(true)
        setSelectedFareTable(sel)
    }, [route, routeType, key, refresh])
    useEffect(() => {
        setCurrentPage(1)
    }, [route, routeType, key, refresh])


    const handleFareTableUpdate = async(data) => {
        if (!Object.keys(data).length > 0) return toast.info('Nothing Changed')
        if(route?.isFreeFare) return toast.error(`Can't Edit Fare as FreeFare is Enabled`)
        if(route?.isFixedFare) return toast.error(`Please Enable Dynamic Fare to Edit Fare`)
        
        data.originId = await data.originId
            data.destinationId= await data.destinationId
            data.amount= await data.amount
            data.childAmount= await Math.ceil((data.amount - Number(Math.round(data.amount*childDiscount/100 + 'e2') + 'e-2'))*10)/10
            data.seniorAmount = await Math.ceil((data.amount - Number(Math.round(data.amount*seniorDiscount/100 + 'e2') + 'e-2'))*10)/10
            data.okuAmount= await Math.ceil((data.amount - Number(Math.round(data.amount*okuDiscount/100 + 'e2') + 'e-2'))*10)/10
            data.foreignChildAmount= await Math.ceil((data.amount - Number(Math.round(data.amount*foreignChildDiscount/100 + 'e2') + 'e-2'))*10)/10
            data.foreignAdultAmount= await Math.ceil((data.amount - Number(Math.round(data.amount*foreignAdultDiscount/100 + 'e2') + 'e-2'))*10)/10
            
    
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.FARE_UPDATE_BY_FARE_TABLE}/${routeId}`,
            data
        }).then(() => {
            toast.success('Fare Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }
    const handleDownloadFareTableCsv = () => {
        if (fareHead.length > 0 && fareVals.length > 0) {

            const stopIds = fareHead.map(({ id }) => id)
            // console.log("stopIds", stopIds);
            let data = ''
            for (let index = 0; index < stopIds.length; index++) {
                if (index == 0) { data += `From ↓ / To →,,` }

                else {
                    data += `${stopIds[index]},`
                }
            }
            data += `\r\n`
            for (let index = 0; index < fareHead.length; index++) {
                if (index == 0) { data += ',Stop,' }
                else {
                    data += `${fareHead[index].name},`
                }
            }
            data += `\r\n`

            fareVals.map((row, index1) => {
                row.map((ele, index2) => {
                    if (index2 == 0) data += `${stopIds[index1 + 1]},`
                    if (index2 <= index1 + 1 && index2 !== 0) {

                        data += '❌,'
                    }
                    else {


                        data += `${ele},`
                    }
                })
                data += `\r\n`

            })
            data += `\r\n`

            var BOM = "\uFEFF";
            var csvContent = BOM + data;
            exportData(csvContent, `fareTable.csv`, 'text/csv;charset=utf-8;')
        }
    }

    const handleDownloadFareTable = () => {
        domtoimage.toPng(document.getElementById('downloadable-fare-table'))
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = `${route.shortName}_fare_table.png`;
                link.href = dataUrl;
                link.click();
            });
    }

    const updateDiscountSubmit = (routeId,data) =>{
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.ROUTE_UPDATE}/${routeId}`,
            data
        }).then(() => {
            toast.success('Route Discount Updated!')
            ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.FARE_UPDATE_BY_ROUTE_ID}/${routeId}`,
                data
            }).then(() => {
                toast.success('Fare Updated!')
                setRefresh(!refresh)
            }).catch(e => {
                toast.error('Fare Not Updated!')
             })
        }).catch(e => {
            toast.error('Route Discount Not Updated!')

         })
        
    }

    const updateDiscount = (e) =>{
        e.preventDefault()
        
        const data = {}
           
            if (childDiscount !== null) {
               
                data.childAmount = childDiscount
            }
            if (seniorDiscount !== null) {
                
                data.seniorAmount = seniorDiscount
            }
            if (okuDiscount !== null) {
                
                data.okuAmount = okuDiscount
            }
            if (foreignAdultDiscount !== null) {
                
                data.foreignAdultAmount = foreignAdultDiscount
            }
            if (foreignChildDiscount !== null) {
                
                data.foreignChildAmount = foreignChildDiscount
            }
            updateDiscountSubmit(routeId,data)
        // onSubmitForm(data)

    }


    const FareCustomTable = () => {
        return (
            <>
                < BTable className='fare-table' id='downloadable-fare-table' responsive bordered hover >
                    {
                        fareHead.length > 0 &&
                        <thead >
                            <tr>
                                {
                                    fareHead.map(({ id, name }, key) => {
                                        if (key === 0) return <th className='text-center align-middle bg-light' title={name} key={key} ><div >{name}</div></th>
                                        return <th className='text-center align-middle' title={name} key={key}><span className='fare-table-header' >{name}</span></th>
                                    })
                                }
                            </tr>
                        </thead>
                    }
                    {
                        currentTableData.length > 0 &&
                        <tbody>
                            {
                                currentTableData.map((row, rowKey) =>
                                    <tr key={rowKey}>
                                        {
                                            row.map((amount, key) => {
                                                if (key === 0) return <th title={amount} key={key} ><div className='fare-table-header-horizontal' >{amount}</div></th>
                                                return <td title={amount === 'x' ? '' : `FROM : ${fareHead[rowKey + 1].name} \nTO : ${fareHead[key].name}`} className={`${amount === 'x' && 'bg-dark'} fare-table-td`} key={key} >{amount === 'x' ? '' : <input onClick={() => handleFareTableEdit(fareHead[rowKey + 1 + ((currentPage - 1) * 10)], fareHead[key], amount)} className='fare-table-td-input' readOnly value={amount} />}</td>
                                            })
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    }
                </BTable>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={fareVals.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </>
        )
    }



    const allowedExtensions = ["csv"];
    const handleCsvImport = (event) => {
        setLoading(true)

        let file = event.target.files[0];
        const fileExtension = file?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
            toast.error("only csv files are allowed!")
            return;
        }
        // console.log("file", file);

        if (!file) return toast.error("Invalid File!")

        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();

        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result);
            const parsedData = csv?.data;
            // const columns = Object.keys(parsedData[0]);
            // console.log("parsedData", parsedData);
            // setData(columns);
            let masterData = []
            for (let x = 2; x < parsedData.length - 1; x++) {
                for (let y = x + 1; y < parsedData[x].length; y++) {

                    const orginId = parsedData[x][0]
                    const destinationId = parsedData[0][y]
                    const amount = parsedData[x][y]
                    // console.log(amount,orginId,destinationId);
                    if (orginId && destinationId && amount >= 0) {

                        let data = {};
                        data.originId = orginId
                        data.destinationId = destinationId
                        data.amount = amount
                        data.childAmount = Math.ceil((data.amount - Number(Math.round(data.amount * childDiscount / 100 + 'e2') + 'e-2')) * 10) / 10
                        data.seniorAmount = Math.ceil((data.amount - Number(Math.round(data.amount * seniorDiscount / 100 + 'e2') + 'e-2')) * 10) / 10
                        data.okuAmount = Math.ceil((data.amount - Number(Math.round(data.amount * okuDiscount / 100 + 'e2') + 'e-2')) * 10) / 10
                        data.foreignChildAmount = Math.ceil((data.amount - Number(Math.round(data.amount * foreignChildDiscount / 100 + 'e2') + 'e-2')) * 10) / 10
                        data.foreignAdultAmount = Math.ceil((data.amount - Number(Math.round(data.amount * foreignAdultDiscount / 100 + 'e2') + 'e-2')) * 10) / 10
                        // console.log("data After", data);
                        masterData.push(data)

                    }
                    else if (amount != 'N/A' && amount != '' && amount) {
                        event.target.value = null;
                        return toast.error(`Fare for StopId ${orginId} to ${destinationId} is invalid!`)
                    }
                }
            }
            if (masterData.length > 0) {
                // console.log("masterData", masterData);
                ApiRequest.fetch({
                    method: 'put',
                    url: `${API_END_POINTS.FARE_UPDATE_BY_FARE_TABLE_BULK}/${routeId}`,
                    data: masterData
                }).then(() => {
                    // console.log("master data response");
                    toast.success('Fare Updated!')
                    event.target.value = null;

                    setLoading(false)
                    setRefresh(!refresh)
                }).catch(e => {
                    event.target.value = null;
                    setLoading(false)
                    setRefresh(!refresh)
                    // console.log("error", e);
                    return toast.error(`something went wrong, Fare not updated!`)
                })
            }
            else {

                toast.success('Fare Updated!')
                setRefresh(!refresh)
                event.target.value = null;

                setLoading(false)
            }
        };

        reader.readAsText(file);
    }

    const TABS = [
        {
            eventKey: 'outbound-fares',
            title: 'Inbound',
            content: <FareCustomTable />
        },
        {
            eventKey: 'inbound-fares',
            title: 'Outbound',
            content: <FareCustomTable />
        }
    ]
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return fareVals.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, route, routeType, key]);


    return (
       
        <div>
            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <Card>
                <Card.Body>
                    {
                        route &&
                        <div>
                            <h4>{route.shortName} - {route.name}</h4>
                            <h4 className='text-capitalize' ><Badge >{`Fixed Fare: ${route?.isFixedFare}`}</Badge></h4>
                            <h4 className='text-capitalize' ><Badge >{`Free Fare: ${route?.isFreeFare}`}</Badge></h4>
                        </div>
                    }
                    
                </Card.Body>
            </Card>
            <Card className=' mt-3 card-max-width'>
                <Card.Body>
                    {
                        route &&
                        <div>
                            <h4>Update Discount:</h4>
                            <row style={{display:"flex",flexWrap: "wrap"}}>
                            <Col lg={4} md={2} >
                                            <Form.Group>
                                                <Form.Label>Child(%)</Form.Label>
                                                <Form.Control type='number' step='1' min='1' max='100' value={childDiscount}  onChange={(e)=>setChildDiscount(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={2} >
                                            <Form.Group>
                                                <Form.Label>Senior(%)</Form.Label>
                                                <Form.Control  type='number' step='1' min='1' max='100'  value={seniorDiscount} onChange={(e)=>setSeniorDiscount(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={2} >
                                            <Form.Group>
                                                <Form.Label>OKU(%)</Form.Label>
                                                <Form.Control type='number' step='1' min='1' max='100' value={okuDiscount} onChange={(e)=>setOkuDiscount(e.target.value)}/>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={2} >
                                            <Form.Group>
                                                <Form.Label>foreignChildDiscount(%)</Form.Label>
                                                <Form.Control type='number' step='1' min='1' max='100' value={foreignChildDiscount} onChange={(e)=>setForeignChildDiscount(e.target.value)}/>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={2} >
                                            <Form.Group>
                                                <Form.Label>foreignAdultDiscount(%)</Form.Label>
                                                <Form.Control type='number' step='1' min='1' max='100' value={foreignAdultDiscount} onChange={(e)=>setForeignAdultDiscount(e.target.value)}/>
                                            </Form.Group>
                                        </Col>
                                        </row>
                                        <row style={{display:"flex",justifyContent: "flex-end"}}>
                                        <button className='fare-discount-button col-md-2' onClick={updateDiscount}>Update the fares</button>
                                        </row>
                        </div>
                    }
                    
                </Card.Body>
            </Card>
            <Card className='h-100 mt-3 card-max-width' >
                <Card.Body>
                    <Card.Title>Fare Table</Card.Title>
                    <Card.Footer className='text-right '>
                        {
                            route && route.stops.length > 0 && (<div className='d-flex justify-content-center '>

                                <p className='row'>  <CloudUpload size={30} /> <h5 className='mr-5'> Upload Fare Table in csv format:</h5>
                                    <input type="file" name="csvImport"
                                        onChange={handleCsvImport} />

                                </p>
                            </div>)}
                    </Card.Footer>
                    {
                        route && route.stops.length > 0 &&
                        <Card.Footer className='text-right' >
                            <Button className='mr-3' onClick={handleDownloadFareTableCsv} >Download Fare Table as csv</Button>

                           </Card.Footer>
                    }

                    {
                        selectedFareTable &&
                        <Modal title='Update Fare Table' show={showEditFareTableModal} onHide={handleClickCancel} >
                            <FareForm edit onSubmitForm={handleFareTableUpdate} onClickCancel={handleClickCancel} defaultValues={selectedFareTable} />
                        </Modal>
                    }
                    {
                        routeType && route && route.stops.length > 0 && fareHead.length > 0  ?
                            routeType == 'Loop' ?
                                <FareCustomTable ></FareCustomTable>

                                : routeType == 'Twoway' ?
                                    <Tabs id="fare-tabs"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3">
                                        {
                                            TABS.map(({ eventKey, title, content }) => (
                                                <Tab key={eventKey} eventKey={eventKey} title={title}>
                                                    <Card className='mt-3 pb-4' >
                                                        <Card.Body>
                                                            {content}
                                                        </Card.Body>
                                                    </Card>
                                                </Tab>

                                            ))
                                        }
                                    </Tabs>
                                    : <Alert variant='warning' >
                                        <Alert.Heading > <ExclamationTriangle size={20} className='text-center mr-2 mb-1' />No Stops Available on this Route</Alert.Heading>
                                        <p>Make sure to create your stop and add it into this route</p>
                                        <Alert.Link as={Link} to={`/editorial/waypoint/${routeId}`} >Add Stop to this route</Alert.Link>
                                    </Alert> : null
                    }
                </Card.Body>


            </Card>
            <LoadingModal loading={loading} />

        </div>


    )
}
export default EditorialFare