import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Col, Collapse, Form, ModalFooter, Row, Spinner } from 'react-bootstrap'
import { Globe, PencilSquare, PlusCircle, PersonBadge, ArrowRepeat } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

import { ContentWrapper, Modal, QRDriverPoster, Table } from '../../components'
import { API_END_POINTS, GENERAL } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import { NoDataInfo } from '../../components/Alert'
import { connect } from 'react-redux'
import { requestUpdateSupervisor } from '../../redux/actions'

// drivers
// - agency_id
// - first_name
// - last_name
// - phone_number
// - qr_code
// - passcode
// - push_notification_token
// - last_login_at
// - is_active

const DriverForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { firstName: '', lastName: '', phoneNumber: '', isActive: '', staffId: '' } }) => {
    const [firstName, setFirstName] = useState(null)
    const [staffId, setStaffId] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [isActive, setIsActive] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()

        const data = {}

        if (firstName !== null) {
            data.firstName = firstName
        }
        if (lastName !== null) {
            data.lastName = lastName
        }
        if (phoneNumber !== null) {
            data.phoneNumber = phoneNumber
        }
        if (isActive !== null) {
            data.isActive = isActive
        }
        if (staffId !== null) {
            data.staffId = staffId
        }

        const dataId = defaultValues.id
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    return (
        <Row>
            <Col className='pt-3' lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Add New Driver</Card.Title>
                            }
                            <Row>
                                <Col md={6} sm={12} >
                                    <Form.Group>
                                        <Form.Label >First Name</Form.Label>
                                        <Form.Control onChange={(e) => setFirstName(e.currentTarget.value)} required={!edit ? true : false} placeholder='John' defaultValue={defaultValues.firstName} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12} >
                                    <Form.Group>
                                        <Form.Label >Last Name</Form.Label>
                                        <Form.Control onChange={(e) => setLastName(e.currentTarget.value)} required={!edit ? true : false} placeholder='Smith' defaultValue={defaultValues.lastName} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label >Phone Number</Form.Label>
                                        <Form.Control onChange={(e) => setPhoneNumber(e.currentTarget.value)} required={!edit ? true : false} placeholder='+60122345678' defaultValue={defaultValues.phoneNumber} />
                                    </Form.Group>
                                </Col>
                                {
                                    edit &&
                                    <Col>
                                        <Form.Group>
                                            <Form.Label >Active</Form.Label>
                                            <Form.Check type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-changes-active-stop' />
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                            <h5>Fill the below details if applicable:</h5>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label >STAFF ID:</Form.Label>
                                        <Form.Control  onChange={(e) => setStaffId(e.currentTarget.value)} defaultValue={defaultValues.staffId} />
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Add Driver' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

const DriverPreviewModal = ({ title, show, onHide, driverData: { id, firstName, lastName, phoneNumber, qrCode } }) => {
    const [agencyData, setAgencyData] = useState(null)
    const handleGetAgency = () => {
        const agency = global.store.getState().user.agency
        setAgencyData(agency)
    }

    useEffect(() => {
        handleGetAgency()
    }, [])

    return (
        <Modal size='md' title={title} show={show} onHide={onHide}  >
            {
                agencyData &&
                <QRDriverPoster name={`${firstName} ${lastName}`} agencyLogoSrc={GENERAL.AGENCY_PHOTO_LOCAL_BASE_URL == null ? agencyData.imageUrl : `${GENERAL.AGENCY_PHOTO_LOCAL_BASE_URL}/${agencyData.imageUrl}`} agencyName={agencyData.name} idNumber={phoneNumber} qrValue={qrCode} />
            }
        </Modal>
    )
}

const SupervisorPreviewModal = ({ data, title, onHide, show, requestUpdateSupervisor }) => {
    const [supervisorName, setSupervisorName] = useState(data?.supervisorName || '')
    const [supervisorPhoneNumber, setSupervisorPhoneNumber] = useState(data?.supervisorPhoneNumber || '')

    const allowUpdate = useMemo(() => {
        if (supervisorName !== (data?.supervisorName || '') || supervisorPhoneNumber !== (data?.supervisorPhoneNumber || '')) {
            return true
        }
        return false
    }, [supervisorName, supervisorPhoneNumber, data])

    const handleUpdateSupervisor = (e) => {
        e.preventDefault()

        const newData = {}
        if (supervisorName !== (data?.supervisorName || '')) {
            newData.supervisorName = supervisorName
        }

        if (supervisorPhoneNumber !== (data?.supervisorPhoneNumber || '')) {
            newData.supervisorPhoneNumber = supervisorPhoneNumber
        }

        requestUpdateSupervisor(newData)
    }

    const handleClose = () => {
        setSupervisorName(data?.supervisorName || '')
        setSupervisorPhoneNumber(data?.supervisorPhoneNumber || '')
        onHide()
    }

    return (
        <Modal title={title} show={show} onHide={handleClose}  >
            {
                data &&
                <Form onSubmit={handleUpdateSupervisor} >
                    <Form.Group>
                        <Form.Label >Supervisor Name</Form.Label>
                        <Form.Control onChange={(e) => setSupervisorName(e.currentTarget.value)} value={supervisorName} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label >Supervisor Contact Number</Form.Label>
                        <Form.Control onChange={(e) => setSupervisorPhoneNumber(e.currentTarget.value)} value={supervisorPhoneNumber} />
                    </Form.Group>
                    {
                        allowUpdate &&
                        <div className='w-100 text-right' >
                            <Button className='mx-1' type='submit' >Update</Button>
                        </div>
                    }
                </Form>
            }
        </Modal>
    )
}

const SupervisorButton = ({ onClick }) => (
    <Button title='View Supervisor info' onClick={onClick} >
        <small className='mr-1' >View Supervisor Info</small>
        <PersonBadge size={25} />
    </Button>
)

const ResetPasscodeModal = ({ driverData: { id, firstName, lastName }, onClose, title, show }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(false)
    const handleResetPasscode = () => {
        setLoading(true)
        if (!id) return toast.warning('Driver id is missing!')
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.DRIVER_RESET_PASSCODE}`,
            data: { id }
        })
            .then((data) => {
                toast.success('Passcode reset success')
                setData(data)
            })
            .catch()
            .finally(() => setLoading(false))
    }
    return (
        <Modal title={title} show={show} onHide={onClose}  >
            <Alert variant='danger' >
                In case of Driver <b>forgotten their driver passcode</b>, Please reset their passcode.<br />
                By clicking RESET button below, Passcode for Driver <b>{firstName} {lastName}</b><br />
                will be reset back to <b>default passcode</b>.
            </Alert>
            <ModalFooter >
                {
                    loading ?
                        <Spinner className='text-primary' animation="border" /> :
                        <Button className='mx-1' onClick={handleResetPasscode} >RESET</Button>
                }
            </ModalFooter>
        </Modal>
    )
}

const EditorialDriver = ({ user, requestUpdateSupervisor }) => {
    const [add, setAdd] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [addCollapse, setAddCollapse] = useState(false)
    const [drivers, setDrivers] = useState(null)
    const [selectedDriverIndex, setSelectedDriverIndex] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [showSupervisorModal, setShowSupervisorModal] = useState(false)
    const [showResetPasscodeModal, setShowResetPasscodeModal] = useState(false)

    const handleToggleAddForm = () => {
        setAdd(!add)
    }

    const handleEdit = (index) => {
        setSelectedDriverIndex(index)
        setShowEditModal(true)
    }

    const handleResetPasscode = (index) => {
        setSelectedDriverIndex(index)
        setShowResetPasscodeModal(true)
    }

    const handlePreviewDriver = (index) => {
        setSelectedDriverIndex(index)
        setShowPreviewModal(true)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['onPreview'] = <Globe className='g-hover-pointer text-primary' onClick={() => handlePreviewDriver(i)} />
            row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            row['lastSeen_'] = <p>{row.lastLoginAt ? `${new Date(row.lastLoginAt).toLocaleDateString()} ${new Date(row.lastLoginAt).toLocaleTimeString()}` : ''}</p>
            row['resetPasscode'] = <ArrowRepeat className='g-hover-pointer text-primary' onClick={() => handleResetPasscode(i)} />
        })
        return data
    }

    const handleGetDrivers = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DRIVER_GET_ALL}`
        }).then((data) => {
            // 
            setDrivers(data)
        }).catch(e => { })
    }

    const handleClickCancel = () => {
        setSelectedDriverIndex(null)
        setAdd(false)
        setShowEditModal(false)
        setShowPreviewModal(false)
    }

    useEffect(() => {
        handleGetDrivers()
    }, [refresh])

    const handleDriverUpdate = (id, data) => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.DRIVER_UPDATE}/${id}`,
            data
        }).then(() => {
            toast.success('Driver Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleDriverCreate = (id, data) => {
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.DRIVER_CREATE}`,
            data
        }).then(() => {
            toast.success(`${data.firstName} ${data.lastName} Driver Created!`)
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const tableHeader = useMemo(() => [
        {
            Header: 'First Name',
            accessor: 'firstName',
            // disableFilters: true
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            // disableFilters: true

        },
        {
            Header: 'Phone Number',
            accessor: 'phoneNumber',
            // disableFilters: true

        },
        {
            Header: 'Staff Id',
            accessor: 'staffId',
            // disableFilters: true

        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            disableFilters: true
        },
        {
            Header: 'Last Login',
            accessor: 'lastSeen_',
            disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'View',
            accessor: 'onPreview',
            disableFilters: true
        },
        {
            Header: 'Reset Passcode',
            accessor: 'resetPasscode',
            disableFilters: true
        }
    ], [])

    return (
        <>
            <ContentWrapper>
                <h2 className='mb-3' >Driver Editorial</h2>
                <div className='divider' />
                <Row >
                    <Col className='pt-3 text-dark' md={10} sm={12} >
                        <Card className='h-100' >
                            <Card.Body>
                                <Card.Title >Active Driver Count</Card.Title>
                                {
                                    drivers &&
                                    <h1>{drivers.length}</h1>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='pt-3' md={2} sm={12} >
                        <Card onClick={handleToggleAddForm} className='bg-primary btn btn-outline-light h-100' >
                            <Card.Body className='d-flex justify-content-center align-items-center' >
                                <PlusCircle size={50} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Collapse in={add} onEnter={() => setAddCollapse(true)} onExited={() => setAddCollapse(false)} >
                    <div>
                        {
                            addCollapse &&
                            <DriverForm onClickCancel={handleClickCancel} onSubmitForm={handleDriverCreate} />
                        }
                    </div>
                </Collapse>
                {
                    drivers && drivers.length > 0 ?
                        <Table titleLeft={<SupervisorButton onClick={() => setShowSupervisorModal(true)} />} numbering title='List of Added Driver' columns={tableHeader} data={appendAction(drivers)} />
                        :
                        <NoDataInfo>
                            No driver added yet.<br />
                            To add a driver simply click the <PlusCircle /> icon above.
                        </NoDataInfo>
                }
            </ContentWrapper>
            {
                drivers && selectedDriverIndex !== null &&
                <Modal title={`Edit Driver ${drivers[selectedDriverIndex].firstName}`} show={showEditModal} onHide={handleClickCancel} >
                    <DriverForm edit defaultValues={drivers[selectedDriverIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleDriverUpdate} />
                </Modal>
            }

            {
                drivers && selectedDriverIndex !== null &&
                <DriverPreviewModal title='Driver Info' driverData={drivers[selectedDriverIndex]} show={showPreviewModal} onHide={handleClickCancel} />
            }

            <SupervisorPreviewModal requestUpdateSupervisor={requestUpdateSupervisor} title='Supervisor Info' data={user?.agency} show={showSupervisorModal} onHide={() => setShowSupervisorModal(false)} />
            {
                showResetPasscodeModal && drivers && selectedDriverIndex !== null &&
                <ResetPasscodeModal driverData={drivers[selectedDriverIndex]} show={showResetPasscodeModal} title='Reset Driver Passcode' onClose={() => setShowResetPasscodeModal(false)} />
            }
        </>
    )
}

export default connect(state => ({
    user: state.user
}), {
    requestUpdateSupervisor
})(EditorialDriver)