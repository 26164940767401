const { NODE_ENV } = process.env;
export const API_URL = NODE_ENV === 'production' ? '' : 'http://localhost:3000';
// export const API_URL = '';

const API_END_POINTS = {
  USER_SIGN_UP: `${API_URL}/api/v1/operator/signup`,
  USER_LOGIN: `${API_URL}/api/v1/operator/signin`,
  USER_UPDATE: `${API_URL}/api/v1/operator/update`,
  USER_UPDATE_ID: `${API_URL}/api/v1/operator/updateid`,
  USER_VERIFY_EMAIL: `${API_URL}/api/v1/operator/verify-email`,
  USER_RESET_PASSWORD: `${API_URL}/api/v1/operator/reset-password`,
  USER_UPDATE_FORGOT_PASSWORD: `${API_URL}/api/v1/operator/update-forgot-password`,

  AGENCY_INFO: `${API_URL}/api/v1/agency`,
  AGENCY_UPDATE_INFO: `${API_URL}/api/v1/agency/update`,
  AGENCY_UPLOAD_PHOTO: `${API_URL}/api/v1/agency/upload`,
  AGENCY_ALL: `${API_URL}/api/v1/agencies`,
  AGENCY_APAD_ACCESS: `${API_URL}/api/v1/agencies/apad`,

  ROUTE_CREATE: `${API_URL}/api/v1/route/create`,
  ROUTE_UPDATE: `${API_URL}/api/v1/route/update`, // /:id
  ROUTE_GET: `${API_URL}/api/v1/route`, // /:id
  ROUTE_GET_STOPS: `${API_URL}/api/v1/route-stops`, // /:id
  ROUTE_GET_ALL: `${API_URL}/api/v1/route-all`,
  ROUTE_UPLOAD_GTFS: `${API_URL}/api/v1/route/gtfs`,
  ROUTE_FINAL_WAYPOINT: `${API_URL}/api/v1/route/final-waypoint`,

  STOP_CREATE: `${API_URL}/api/v1/stop/create`,
  STOP_UPDATE: `${API_URL}/api/v1/stop/update`, // /:id
  STOP_GET: `${API_URL}/api/v1/stop`, //:id
  STOP_GET_ALL: `${API_URL}/api/v1/stop-all`,
  STOP_UPLOAD_GTFS: `${API_URL}/api/v1/stop/gtfs`,

  VEHICLE_CREATE: `${API_URL}/api/v1/vehicle/create`,
  VEHICLE_RESET_CODE: `${API_URL}/api/v1/vehicle/reset-code`, // /:id
  VEHICLE_UPDATE: `${API_URL}/api/v1/vehicle/update`, // /:id
  VEHICLE_GET: `${API_URL}/api/v1/vehicle`, // /:id
  VEHICLE_GET_ALL: `${API_URL}/api/v1/vehicle-all`,

  DRIVER_CREATE: `${API_URL}/api/v1/driver/create`,
  DRIVER_UPDATE: `${API_URL}/api/v1/driver/update`, // /:id
  DRIVER_GET: `${API_URL}/api/v1/driver`, // /:id
  DRIVER_GET_ALL: `${API_URL}/api/v1/driver-all`,
  DRIVER_RESET_PASSCODE: `${API_URL}/api/v1/driver/reset-passcode`,

  SCHEDULE_CREATE: `${API_URL}/api/v1/schedule/create`, // /:routeId
  SCHEDULE_UPDATE: `${API_URL}/api/v1/schedule/update`, // /:routeId

  // schedulesv2
  SCHEDULEV2_CREATE: `${API_URL}/api/v1/schedulev2/create`,
  SCHEDULEV2_CREATE2: `${API_URL}/api/v1/schedulev2/create2`,
  SCHEDULEV2_DELETE: `${API_URL}/api/v1/schedulev2/delete`,
  SCHEDULEV2_DELETE_RECORD: `${API_URL}/api/v1/schedulev2/delete-record`,
  SCHEDULEV2_GET_ALL: `${API_URL}/api/v1/schedulev2/schedule-all`,
  SCHEDULEV2_FIND_ALL: `${API_URL}/api/v1/schedulev2/schedule`,
  SCHEDULEV2_UPDATE: `${API_URL}/api/v1/schedulev2/update`,
  SCHEDULEV2_UPDATE_TIME: `${API_URL}/api/v1/schedulev2/update/time`,
  SCHEDULEV2_UPDATE_NAME: `${API_URL}/api/v1/schedulev2/updateName`,
  SCHEDULEV2_UPDATE2: `${API_URL}/api/v1/schedulev2/update2`,


  // schedulev2timetable
  SCHEDULEV2TIMETABLE_CREATE: `${API_URL}/api/v1/schedulev2timetable/create`,
  SCHEDULEV2TIMETABLE_GET: `${API_URL}/api/v1/schedulev2timetable/schedule`,
  SCHEDULEV2TIMETABLE_GET_ALL: `${API_URL}/api/v1/schedulev2timetable/schedule-all`,
  SCHEDULEV2TIMETABLE_GET_ALL_BY_DAY: `${API_URL}/api/v1/schedulev2timetable/schedule-all/day`,
  SCHEDULEV2TIMETABLE_GET_DETAIL: `${API_URL}/api/v1/schedulev2timetable/schedule-detail`,
  SCHEDULEV2TIMETABLE_UPDATE: `${API_URL}/api/v1/schedulev2timetable/update`,
  SCHEDULEV2TIMETABLE_UPDATE_TIME: `${API_URL}/api/v1/schedulev2timetable/update/time`,
  SCHEDULEV2TIMETABLE_DELETE_RECORD: `${API_URL}/api/v1/schedulev2timetable/delete-record`,
  SCHEDULEV2TIMETABLE_DELETE: `${API_URL}/api/v1/schedulev2timetable/delete`,
  SCHEDULEV2TIMETABLE_UPDATE_CHECKBOX: `${API_URL}/api/v1/schedulev2timetable/update2`,
  SCHEDULEV2TIMETABLE_UPDATE_NAME: `${API_URL}/api/v1/schedulev2timetable/updateName`,
  SCHEDULEV2TIMETABLE_TOGGLE_IS_ACTIVE: `${API_URL}/api/v1/schedulev2timetable/toggleIsActive`,

  TRIP_SCHEDULER                 : `${API_URL}/api/v1/scheduled-trips`, // GET POST PUT:tripId for put
  TODAY_SCHEDULER_STAT           : `${API_URL}/api/v1/scheduled/today`,
  TRIP_COLLECTION                : `${API_URL}/api/v1/trip/history`,
  TRIP_WITH_STOPS_COLLECTION                : `${API_URL}/api/v1/trip-stops/history`,
  
  GET_TRIP_LOG                   : `${API_URL}/api/v1/trip-log`, 
  GET_TRIP_LOG_BULK                   : `${API_URL}/api/v1/trip-log-bulk`, 
  GET_DATE_TRIP_LOG              : `${API_URL}/api/v1/date-trip-log`, 
  GET_VEHICLE_TRIPS              : `${API_URL}/api/v1/trips/vehicle`, 

  FARE_CREATE: `${API_URL}/api/v1/fare/create`, // /:routeId
  FARE_UPDATE: `${API_URL}/api/v1/fare/update`, // /:routeId update-by-fare-table
  FARE_GET: `${API_URL}/api/v1/fare`, // /:routeId
  FARE_UPDATE_BY_FARE_TABLE: `${API_URL}/api/v1/fare/update-by-fare-table`, // /:routeId 
  FARE_UPDATE_BY_ROUTE_ID: `${API_URL}/api/v1/fare/update-by-route-id`, // /:routeId 
  FARE_UPDATE_BY_FARE_TABLE_BULK: `${API_URL}/api/v1/fare/bulk/update-by-fare-table`, // /:routeId 

  WAYPOINT_CREATE: `${API_URL}/api/v1/waypoint/create`, // /:routeId
  WAYPOINT_UPDATE: `${API_URL}/api/v1/waypoint/update`, // /:id
  WAYPOINT_GET_ALL: `${API_URL}/api/v1/waypoints`,

  TRANSACTION_GET_ALL: `${API_URL}/api/v1/transactions/history`,
  TRANSACTION_RIDERSHIP_GET_ALL: `${API_URL}/api/v1/transactions/ridership/history`,
  TRANSACTION_GET_TODAY: `${API_URL}/api/v1/transactions/today`,
  SETTLEMENT_GET_ALL: `${API_URL}/api/v1/settlement/history`,
  SETTLEMENT_GET_ALL_BY_DATE_RANGE: `${API_URL}/api/v1/settlement/history-date-range`,

  SCHEDULE_UPLOAD_GTFS_BY_ROUTE: `${API_URL}/api/v1/schedule/gtfs/:routeId`,
  SCHEDULE_UPLOAD_GTFS: `${API_URL}/api/v1/schedule/gtfs-all`,

  FARE_UPLOAD_GTFS_BY_ROUTE: `${API_URL}/api/v1/fare/gtfs/:routeId`,
  FARE_UPLOAD_GTFS: `${API_URL}/api/v1/fare/gtfs-all`,

  SHAPE_UPLOAD_GTFS: `${API_URL}/api/v1/shape/gtfs-all`,

  ACTIVE_TRIP: `${API_URL}/api/v1/active-trips`,
  AGENCY_TRIP: `${API_URL}/api/v1/agency-trips`,
  USER_EXPERIENCE: `${API_URL}/api/v1/trip-experience`,
  USER_EXPERIENCE_PJCITYBUS: `${API_URL}/api/v1/pjcitybus/experiences`,
  UPDATE_USER_EXPERIENCE_CASE: `${API_URL}/api/v1/case`,//:caseId 

  CREATE_BASIC_USER: `${API_URL}/api/v1/basic/create`,
  VERIFY_BASIC_USER: `${API_URL}/api/v1/basic/verify-me`, // /:emailCode
  UPDATE_BASIC_USER: `${API_URL}/api/v1/basic/update`, // /:userId
  GET_BASIC_USER: `${API_URL}/api/v1/basic/get-all`,

  DEVICE_FIND_ALL: `${API_URL}/api/v1/devices`,
  DEVICE_FIND_UNUSED_TOKEN: `${API_URL}/api/v1/devices/unused`,
  DEVICE_CREATE: `${API_URL}/api/v1/devices`,
  DEVICE_RESET_CODE: `${API_URL}/api/v1/devices/reset`, ///:id
  DEVICE_UPDATE_USER_ID: `${API_URL}/api/v1/devices/update-user-id`, ///:id

  UPDATE_SUPERVISOR: `${API_URL}/api/v1/supervisor`,
  GET_ATTACHMENT_URL: `${API_URL}/api/v1/attachment`,//:filename

  TRIP_SCHEDULER: `${API_URL}/api/v1/scheduled-trips`, // GET POST PUT:tripId for put
  TRIP_SCHEDULER_PAST_WEEK: `${API_URL}/api/v1/scheduled-trips/past-week`, // GET POST PUT:tripId for put
  TODAY_SCHEDULER_STAT: `${API_URL}/api/v1/scheduled/today`,
  TRIP_COLLECTION: `${API_URL}/api/v1/trip/history`,
  TRIP_UPDATE_BY_ID     : `${API_URL}/api/v1/scheduled-trips/updateById`, // /:id

  // GET_TRIP_LOG: `${API_URL}/api/v1/trip-log`,
  // GET_DATE_TRIP_LOG: `${API_URL}/api/v1/date-trip-log`,
  // GET_VEHICLE_TRIPS: `${API_URL}/api/v1/trips/vehicle`,

  TRAVEL_COLLECTION: `${API_URL}/api/v1/vehicle-travel/history`,

  DRIVER_COLLECTION: `${API_URL}/api/v1/driver2/history`,
  ROUTE_COLLECTION: `${API_URL}/api/v1/route/history`,
  CLAIM_COLLECTION: `${API_URL}/api/v1/claim/history`,
  CLAIM_COLLECTION_BY_DATE: `${API_URL}/api/v1/claim/history/byDate`,
  ISBSF_COLLECTION_BY_DATE_AT_BACKEND: `${API_URL}/api/v1/isbsf-backend/history/byDate`,
  MISSED_COLLECTION: `${API_URL}/api/v1/missed/history`,
  MISSED_COLLECTION_BY_DATE: `${API_URL}/api/v1/missed/history/byDate`,
  CLAIM_DETAILS_COLLECTION: `${API_URL}/api/v1/claimDetails/history`,
  CLAIM_DETAILS_COLLECTION_BY_DATE: `${API_URL}/api/v1/claimDetails/history/byDate`,
  CLAIM_DETAILS_COLLECTION_BY_DATE_BACKEND: `${API_URL}/api/v1/claimDetails/history/byDate/backend`,
  APAD_SCOREBOARD_COLLECTION: `${API_URL}/api/v1/apad-scoreboard`,

  VERIFY_SUPER_ADMIN             : `${API_URL}/api/super/super-admin/verify`,
  GET_APAD_AGENCY                : `${API_URL}/api/super/apad`,
  UPDATE_APAD_ITG                : `${API_URL}/api/super/apad-itg`,
  UPDATE_APAD_REPORT                : `${API_URL}/api/super/apad-report`,
 
  // APAD
  UPDATE_APAD_ACCESS                : `${API_URL}/api/v1/apad-access`,


  CHECK_GPS_LOG                : `${API_URL}/api/v1/trip/check-gps-log`,

};

export default API_END_POINTS
